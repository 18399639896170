import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import QS from 'query-string';
import { Component, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { MobXProviderContext } from 'mobx-react';
import Clickable from '../components/clickable';
import LanguageDropdown from '../components/language-dropdown';
import MaskLoading from '../components/mask-loading';
import LoginModal from '../components/modal/login-modal';
import LoginTCGModal from '../components/modal/modal-login-dm2';
import LoginPassportModal from '../components/modal/modal-login-passport';
import ModalToast from '../components/modal/modal-toast';
import Thumbnail from '../components/thumbnail';
import Typography from '../components/typography';
import { CHAIN_EXPLORER } from '../constants/common';
import { useAuth } from '../hooks/auth';
import { Colors, Images } from '../theme';
import Format from '../utils/format';
import { isTokenExpired } from '../utils/jwt';
import Media from '../utils/media';
import { STORAGE, getLocalStorage, setLocalStorage } from '../utils/storage';
import ChainBox from './chain-box';

const HeaderContainer = styled.header`
  font-family: 'DF-SouGei-W5', sans-serif;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.2s;
  font-weight: bold;
  height: 446px;
  min-height: 60px;
  background-image: url('${Images.BG_HEADER_HOME}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .display-none {
    display: none !important;
  }
  &.my-page {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.9) 100%),
        url('${Images.BG_HEADER_MY_PAGE}');
    height: 333px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 80%;
  }  


  &.header-detail {
    height: 420px;
    width: 100%;
    aspect-ratio: 0;
    box-shadow: none;
    background-image: ${props =>
      `linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.9) 100%), url(${`${
        props.bgHeaderDetail || Images.BG_HEADER_MY_PAGE
      }`})`};
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: ${props =>
        `linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.9) 100%), url(${`${
          props.bgHeaderDetail || Images.BG_HEADER_MY_PAGE
        }`})`};
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(15px);
    }
    ${Media.greaterThan(Media.SIZE.LG)} {
      height: auto;
      background-image: none;
    }
  }
  ${Media.lessThan(Media.SIZE.XL)} {
    height: 333px;
    background-image: url('${Images.BG_HEADER_HOME}');
    &.header-presale {
      width: 100%;
      min-height: 300px;
      aspect-ratio: 1;
      background-image: url(${`${Images.AUTUMN_PRESALE}`});
      background-repeat: no-repeat;
      background-size: cover;
      height: 720px;
    }

    &.header-detail {
      width: 100%;
      aspect-ratio: 0;
      box-shadow: none;
    }
  }

  .text-right {
    text-align: right;
  }

  .wrap-box {
    width: 100%;
    position: fixed;
    z-index: 2;
    background-color: #013fb5;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    &-content {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      ${Media.greaterThan(Media.SIZE.LG)} {
        padding: 10px 52px;
      }
    }
  }

  .left-box {
    display: flex;
    flex: 1;

    ${Media.lessThan(Media.SIZE.LG)} {
      .menu-button {
        margin-left: 3px;
        margin-right: 15px;

        img {
          width: 17px;
        }
      }
    }divdex: 2;
      align-items: center;
      display: flex;
      color: #fff;
      max-height: 100%;
      width: auto;
      min-width: 130px;
      height: 38px;

      img {
        padding-right: 25px;
        height: 38px;
        width: 100%;
        object-fit: contain;
        ${Media.lessThan(Media.SIZE.XXXS)} {
          max-width: 100px;
          height: 50px;
        }
        ${Media.lessThan(Media.SIZE.LG)} {
          max-width: 150px;
          z-index: 99;
        }
      }
    }

    .menu-list {
      display: flex;
      height: 100%;

      ${Media.lessThan(Media.SIZE.LG)} {
        display: none;
      }

      .menu-item {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${Colors.TEXT};
        white-space: nowrap;
        padding: 0 10px;
        cursor: pointer;
        &.menu-item-ja {
          ${Media.lessThan(Media.SIZE.XL)} {
            padding-right: 10px;
            word-spacing: -20px;
            padding-left: 10px;
            padding-right: 0;
          }
        }
        .sub-name {
          position: absolute;
          right: 0;
          top: 20px;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 10px;
          border-radius: 2px;
          line-height: 14px;
        }

        .typography {
          white-space: nowrap;
          color: ${Colors.TEXT};
          font-weight: bold;
          font-size: 16px;

          ${Media.lessThan(Media.SIZE.XL)} {
            font-size: 14px;
          }
        }

        &:hover {
          opacity: 0.5;
        }

        &.active {
          color: #3cc8fc;

          p,
          .typography {
            color: #3cc8fc;
          }
        }

        ${Media.lessThan(Media.SIZE.XL)} {
          font-size: 14px;
        }
      }
    }

    .menu-external {
      font-size: 16px;
    }
  }

  .language-box {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    flex: 1;
    ${Media.lessThan(Media.SIZE.LG)} {
      .menu-list {
        display: none;
      }
    }
    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      gap: 4px;
      line-height: 22px;
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;
      background: #2164E1;
      border-radius: 8px; 
      img {
        width: 23px;
      }
    }

    .menu {
      cursor: pointer;
    }
    .menu-button {
      margin-left: 20px;
      width: 20px;
      justify-content: center;
      display: flex;
      align-items: center;

      img {
        filter: brightness(0) invert(1);
      }
    }

    .login-button {
      border: none;
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: transparent;
      padding: 7px 4px;
      cursor: pointer;
      background-color: #296CEC;
      border-radius: 8px;
      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #ffffff;
      }
    }
  }

  .center-box {
    ${Media.greaterThan(Media.SIZE.MD)} {
      display: none;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: transparent;
    &_breads {
      display: flex;
      align-items: center;
    }
  }

  .menu-panel {
    background-color: #0E0F23;
    position: fixed;
    width: 375px;
    height: 100vh;
    top: 57px;
    bottom: 0;
    right: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    ${Media.lessThan(Media.SIZE.LG)} {
      top: 57px;
      width: 100%;
    }

    .top-menu-box {
      padding: 11px 0px;
      margin: 0 14px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-bottom: solid 1px #013FB5;
      &-user {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-avatar {
        display: flex;
        align-items: center;
        gap: 10px;
        line-height: 16px;
        font-size: 16px;
        color: #ffffff;
      }
      &-logout {
        cursor: pointer;
      }
      .menu-item {
        display: flex;
        justify-content: space-between;
        height: 56px;
        align-items: center;
        padding: 0 20px;

        .typography {
          color: #ffffff;
        }

        > img {
          width: 8px;
        }

        &.language-item {
          display: none;
          ${Media.lessThan(Media.SIZE.LG)} {
            display: flex;
          }
        }
      }
    }

    .bottom-menu-box {
      margin-top: 14px;

      .bottom-menu-item {
        width: 100%;
        padding: 4px 14px;
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        color: #ffffff;

        &:hover {
          background-color: #262739;
        }
      }
    }
  }

  &.open {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .store-info {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    &-content {
      position: absolute;
      bottom: 8px;
      left: 16px;
      display: flex;
      gap: 10px;
      align-items: flex-end;
      color: #ffffff;
      ${Media.greaterThan(Media.SIZE.LG)} {
        left: 52px;
      }
      &-avatar {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        object-fit: center;
      }
      &-data {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-name {
          font-size: 23px;
          line-height: 23px;
          font-weight: 400;
        }
        &-create {
          display: flex;
          gap: 10px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
        }
        &-hash {
          cursor: pointer;
          color: #007AFF;
        }
      }
    }
  }
`;

@withTranslation('common')
@withRouter
@inject(stores => ({
  authStore: stores.auth,
  categoriesStore: stores.categories,
  uiStore: stores.ui,
  usersStore: stores.users,
}))
@observer
class Header extends Component {
  static propTypes = {
    authStore: PropTypes.object,
    location: PropTypes.object,
    categoriesStore: PropTypes.object,
    uiStore: PropTypes.object,
    usersStore: PropTypes.object,
    // t: PropTypes.object,
  };

  state = {
    isOpenMenuPanel: false,
    isOpenUserPanel: false,
    isOpenSearchMobile: false,
    classFixedHeader: '',
    // isOpenLoginModal: false,
    isOpenRegisterModal: false,
    isOpenMenu: false,
  };

  _onCloseAllPanel = () => {
    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _onLogout = async e => {
    e?.preventDefault();
    try {
      MaskLoading.close(true);
      const { logoutAction } = this.props;
      this._onCloseAllPanel();
      await logoutAction();
    } catch (err) {
      MaskLoading.close(false);
    }
  };

  _onOpenMyPage = e => {
    const { history } = this.props;
    e.preventDefault();

    history.push('/my-page');
    this._onCloseAllPanel();
  };

  _onOpenForm = e => {
    const { history } = this.props;
    e.preventDefault();

    history.push('/settings');
    this._onCloseAllPanel();
  };

  _onBackHome = () => {
    const { history } = this.props;
    history.push('/');
    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
      defaultCategoryIdSelect: '',
      defaultSearchValue: '',
    });
  };

  _onToggleMenuPanel = () => {
    this.setState(state => ({
      isOpenMenuPanel: !state.isOpenMenuPanel,
      isOpenSearchMobile: false,
      isOpenUserPanel: false,
    }));
  };

  _onToggleUserPanel = () => {
    this.setState(state => ({
      isOpenUserPanel: !state.isOpenUserPanel,
      isOpenSearchMobile: false,
      isOpenMenuPanel: false,
    }));
  };

  _onToggleSearchMobile = () => {
    this.setState(state => ({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: !state.isOpenSearchMobile,
    }));
  };

  _onMenuPanelClick = item => {
    const { history } = this.props;

    history.push(item.to);

    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _renderMenuPanel = () => {
    const { t, authStore, routeDetail, usersStore } = this.props;
    const { isOpenMenuPanel } = this.state;
    const { toggleOpenModalLogin } = authStore;

    if (!isOpenMenuPanel) return null;

    const publicAddress = getLocalStorage(STORAGE.PUBLIC_ADDRESS);
    const accessToken = getLocalStorage(STORAGE.ACCESS_TOKEN);
    const isLogin = !!accessToken && !!publicAddress;

    const pathName = routeDetail?.path || '';

    const BOTTOM_MENU_ITEMS = [
      {
        name: t('header.home'),
        path: '/',
        icon: Images.HOME_ICON,
        iconActive: Images.HOME_ACTIVE_ICON,
      },
      {
        name: t('header.my_page'),
        path: '/my-page',
        icon: Images.MY_PAGE_ICON,
        iconActive: Images.MY_PAGE_ACTIVE_ICON,
      },
      {
        name: t('header.my_store'),
        path: '/my-store',
        icon: Images.MY_STORE_ICON,
        iconActive: Images.MY_STORE_ACTIVE_ICON,
      },
    ];

    const userData = JSON.parse(getLocalStorage(STORAGE.USER_DATA)) || usersStore?.userData;

    return (
      <div className="menu-panel">
        {isLogin && (
          <div className="top-menu-box">
            <div className="top-menu-box-user">
              <div className="top-menu-box-avatar">
                <Thumbnail rounded size={42} url={userData?.userImage} placeholderUrl={Images.USER_PLACEHOLDER} />
                <div>{userData?.userName}</div>
              </div>
              <img
                className="top-menu-box-logout"
                src={Images.WHITE_LOGOUT_ICON}
                alt="logout"
                onClick={this._onLogout}
              />
            </div>
          </div>
        )}
        <div className="bottom-menu-box">
          {BOTTOM_MENU_ITEMS.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className="bottom-menu-item"
              onClick={isLogin ? this._onCloseAllPanel : toggleOpenModalLogin}
            >
              <img src={pathName === item.path.replace('/', '') ? item.iconActive : item.icon} alt="menu icon" />{' '}
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    );
  };

  _renderUserPanel = () => {
    const { t, authStore } = this.props;
    const { isOpenUserPanel } = this.state;

    const isLogin = authStore.loggedIn && authStore?.initialData?.publicAddress;

    if (!isOpenUserPanel) return null;
    return (
      <div className="menu-panel">
        <div className="top-menu-box">
          <Clickable className="menu-item" onClick={this._onOpenMyPage}>
            <Typography>{t('header.my_page')}</Typography>
            <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
          </Clickable>
          <Clickable className="menu-item" onClick={this._onOpenForm}>
            <Typography>{t('header.edit_profile')}</Typography>
            <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
          </Clickable>
        </div>
        <div className="bottom-menu-box">
          <ChainBox onSelected={this._onCloseAllPanel} />
          {isLogin && (
            <Clickable className="language-box bottom-menu-item" onClick={this._onLogout}>
              {t('header.logout')}
            </Clickable>
          )}
        </div>
      </div>
    );
  };

  _openPublicAdderss = contractAddress => {
    const chainID = getLocalStorage(STORAGE.CHAIN_ID);
    if (chainID && contractAddress) {
      const link = `${CHAIN_EXPLORER[chainID]}/address/${contractAddress}`;
      window.open(link, '_blank');
    }
  };

  _renderStoreInfo = () => {
    const { t, routeDetail, authStore, usersStore } = this.props;
    if (routeDetail?.path !== 'my-page' && routeDetail?.path !== 'my-store') return null;
    const userData = JSON.parse(getLocalStorage(STORAGE.USER_DATA)) || usersStore?.userData;
    const publicAddress = getLocalStorage(STORAGE.PUBLIC_ADDRESS) || authStore?.initialData?.publicAddress;

    return (
      <div className="store-info">
        <div className="store-info-content">
          <img
            className="store-info-content-avatar"
            src={userData?.userImage || Images.USER_PLACEHOLDER}
            alt="avatar"
          />
          <div className="store-info-content-data">
            <div className="store-info-content-data-name">{userData?.userName}</div>
            <div className="store-info-content-data-create">
              {t('contact.address')}
              <span className="store-info-content-data-hash" onClick={() => this._openPublicAdderss(publicAddress)}>
                {Format.shortenAddress(publicAddress)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.setState({ classFixedHeader: 'header-blur-active' });
      } else {
        this.setState({ classFixedHeader: '' });
      }
    });
  }

  _onChangeLanguage = language => {
    const { i18n, location, history } = this.props;

    if (language === i18n.language) return;

    i18n.changeLanguage(language, () => {
      let newPath = location.pathname.replace('/en/', '/');
      newPath = newPath.replace('/ja/', '/');
      setLocalStorage(STORAGE.CURRENT_LANG, language);
      let { query } = QS.parse(location.search);
      if (query) {
        history.replace(`${newPath}?query=${query}`);
      } else {
        history.replace(newPath);
      }
      let element = document.getElementById('web-body');
      element.removeAttribute('class');
      element.classList.add(`${language}`);
    });
  };

  _showMenu = () => {
    this.setState({
      isOpenMenuPanel: false,
      isOpenSearchMobile: false,
    });
  };

  render() {
    const { authStore, uiStore, usersStore, t, routeDetail, modalToast, setModalToast } = this.props;
    const {
      isOpenLoginModal,
      toggleOpenModalLogin,
      toggleOpenImmutableModalLogin,
      toggleOpenTCGCModalLogin,
      isOpenLoginImmutableModal,
      isOpenLoginTCGCModal,
    } = authStore;
    const { isOpenMenuPanel, classFixedHeader } = this.state;
    const MENU_ITEMS_MOBILE = [
      {
        name: t('header.nft_items'),
        to: '/search-products',
      },
    ];

    const token = getLocalStorage(STORAGE.ACCESS_TOKEN);
    const isLogin = !!token;
    const balanceValue = JSON.parse(getLocalStorage(STORAGE.USER_DATA))?.balance || usersStore?.userData?.balance || 0;

    return (
      <div className="header">
        <HeaderContainer
          className={classnames(
            {
              open: isOpenMenuPanel,
              'header-sub': routeDetail,
              'header-detail': routeDetail?.path === 'product-details',
              'header-presale': routeDetail?.path === 'presale',
              'my-page': routeDetail?.path === 'my-page' || routeDetail?.path === 'my-store',
            },
            classFixedHeader,
          )}
          bgHeaderDetail={uiStore?.bgHeaderDetail}
        >
          <div className="wrap-box">
            <div className="wrap-box-content">
              <div className="left-box">
                <Clickable className="logo" onClick={this._onBackHome}>
                  <img src={Images.LOGO} alt="logo" />
                </Clickable>
              </div>
              <div className="right-box">
                {isLogin && (
                  <div className="right-box-price">
                    <img src={Images.BCOIN_ICON_NEW} alt="btn-b-coin" />
                    <p>{Format.price(balanceValue)}</p>
                  </div>
                )}
                <LanguageDropdown />
                {!isLogin && (
                  <button className="login-button" onClick={toggleOpenModalLogin}>
                    <img className="login-button-icon" src={Images.PROFILE_ICON} alt="profile" />
                    <div className="login-button-text">{t('auth:login.login')}</div>
                  </button>
                )}
                {isLogin && (
                  <img
                    className="menu"
                    src={isOpenMenuPanel ? Images.MENU_CLOSE_ICON : Images.MENU_ICON}
                    alt="menu"
                    onClick={this._onToggleMenuPanel}
                  />
                )}
              </div>

              {this._renderMenuPanel(MENU_ITEMS_MOBILE)}
              {this._renderUserPanel()}
            </div>
          </div>

          {this._renderStoreInfo()}
        </HeaderContainer>
        <LoginModal
          onClose={toggleOpenModalLogin}
          isOpen={isOpenLoginModal}
          authStore={authStore}
          setModalToast={setModalToast}
        />
        <LoginPassportModal
          onClose={toggleOpenImmutableModalLogin}
          isOpen={isOpenLoginImmutableModal}
          setModalToast={setModalToast}
        />
        <LoginTCGModal onClose={toggleOpenTCGCModalLogin} isOpen={isOpenLoginTCGCModal} setModalToast={setModalToast} />
        <ModalToast
          isOpen={modalToast.isShow}
          description={modalToast?.description}
          type={modalToast.type}
          textSuccess={t('common:success')}
          textFail={t('common:fail')}
          onClose={() =>
            setModalToast({
              ...modalToast,
              isShow: false,
            })
          }
          onOk={() => {
            setModalToast({
              ...modalToast,
              isShow: false,
            });
            // modalToast && modalToast?.actionToast();
          }}
        />
      </div>
    );
  }
}

// export default Header;
export default withWeb3AuthHook(Header);

function withWeb3AuthHook(Component) {
  return function WrappedComponent(props) {
    const { logoutAction } = useAuth();
    const { auth, users } = useContext(MobXProviderContext);
    const ACCESS_TOKEN = getLocalStorage(STORAGE.ACCESS_TOKEN);
    const isExpired = ACCESS_TOKEN && isTokenExpired(ACCESS_TOKEN);

    const fetchUserInfo = async () => {
      try {
        await Promise.all([users.getUserInfo(), users.getUserResource()]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    useEffect(() => {
      if (auth?.loggedIn) {
        fetchUserInfo();
      }
      // eslint-disable-next-line
    }, [auth, auth?.loggedIn]);

    useEffect(() => {
      if (isExpired) {
        logoutAction();
        auth?.setLoggedIn(false);
      }
      // eslint-disable-next-line
    }, []);

    const [modalToast, setModalToast] = useState({
      isShow: false,
      type: 'success',
      description: '',
    });
    return <Component {...props} logoutAction={logoutAction} modalToast={modalToast} setModalToast={setModalToast} />;
  };
}
