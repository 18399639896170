import { types } from 'mobx-state-tree';
import moment from 'moment';

import {
  favorite,
  getAuthorProductPrice,
  getMyProducts,
  getMyTransactionHistories,
  getPriceRate,
  getProductCategories,
  getProductDetails,
  getProductFilterData,
  getProductItems,
  getProductMyPageItems,
  getProductPrice,
  getTransactionHistories,
} from '../api/products';
import { Model } from '../utils/mobx-model-helper';

const TYPES = {
  GET_PRODUCTS: 1,
  GET_EVENT_PRODUCTS: 2,
  GET_MY_PRODUCTS: 3,
  GET_MY_BID_PRODUCTS: 4,
  GET_PRODUCT_DETAILS: 5,
  GET_PRODUCT_CATEGORIES: 6,
  GET_PRODUCT_FILTER_DATA: 7,
  GET_PRICE_RATE: 8,
  GET_MY_SELL_PRODUCTS: 9,
  GET_MY_SOLD_PRODUCTS: 10,
  GET_PRICE_CHART_DATA: 11,
  GET_TRANSACTION_HISTORIES: 12,
  GET_SEARCH_PRODUCTS: 13,
  FAVORITE: 14,
  GET_HOT_RANKING_PRODUCTS: 15,
  GET_RECOMMENDED_PRODUCTS: 16,
  GET_MY_TRANSACTION_HISTORIES: 17,
  GET_PRODUCTS_WISH: 18,
  GET_MY_OFFER_PRODUCTS: 19,
  GET_PRODUCT_PRICE: 20,
  GET_AUTHOR_PRODUCT_PRICE: 21,
  GET_CULTURE_OVERVIEW: 22,
  GET_PRODUCT_ITEMS: 23,
  GET_PRODUCT_ERC1155: 25,
  GET_PRODUCT_BY_CREATOR_ID: 26,
  GET_CURRENCY_PRODUCT: 26,
  GET_MY_PAGE_PRODUCT: 27,
};

const NEW_RANGE_HOURS = 24;

const Category = types.model('Category').props({
  id: types.identifierNumber,
  name: types.string,
  value: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
});

const NftHunterModal = types
  .model('NftHunterModal')
  .props({
    current_life_gauge: types.maybeNull(types.number),
    treasure_hunter_init_value: types.maybeNull(types.number),
    prize_hunter_init_value: types.maybeNull(types.number),
    arena_hunter_init_value: types.maybeNull(types.number),
    merchant_init_value: types.maybeNull(types.number),
    mechanic_init_value: types.maybeNull(types.number),

    // traits
    arena_hunter_trait: types.maybeNull(types.boolean),
    mechanic_trait: types.maybeNull(types.boolean),
    merchant_trait: types.maybeNull(types.boolean),
    prize_hunter_trait: types.maybeNull(types.boolean),
    treasure_hunter_trait: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const NftBountyModal = types
  .model('NftBountyModal')
  .props({
    current_energy_gauge: types.maybeNull(types.number),
    gimmick_id: types.maybeNull(types.string),
    trait: types.maybeNull(types.string),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const NftGauntletModal = types
  .model('NftGauntletModal')
  .props({
    trait: types.maybeNull(types.string),
    number_slots_exception: types.maybeNull(types.number),
    boost_value: types.maybeNull(types.number),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const RarityModel = types
  .model('RarityModel')
  .props({
    max_life_gauge: types.maybeNull(types.number),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const BidsHistory = types.model('BidsHistory').props({
  bidPrice: types.maybeNull(types.number),
  buyingFlag: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  productBidHistoryId: types.maybeNull(types.number),
  productBidId: types.maybeNull(types.number),
  productId: types.maybeNull(types.number),
  status: types.boolean,
  userAvatar: types.maybeNull(types.string),
  userBidStatus: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  userName: types.maybeNull(types.string),
  userPublicAddress: types.maybeNull(types.string),
});

const ProductDetails = types
  .model('ProductDetails')
  .props({
    product_id: types.identifier,
    ap_arena_hunter: types.maybeNull(types.number),
    ap_mechanic: types.maybeNull(types.number),
    ap_merchant: types.maybeNull(types.number),
    ap_prize_hunter: types.maybeNull(types.number),
    ap_treasure_hunter: types.maybeNull(types.number),
    arena_hunter_init_value: types.maybeNull(types.number),
    mechanic_init_value: types.maybeNull(types.number),
    merchant_init_value: types.maybeNull(types.number),
    mechanic_trait: types.maybeNull(types.boolean),
    merchant_trait: types.maybeNull(types.boolean),
    arena_hunter_trait: types.maybeNull(types.boolean),
    nft_hunter_id: types.maybeNull(types.string),
    arena_characteristic: types.maybeNull(types.boolean),
    currency_unit: types.maybeNull(types.string),
    current_ap_arena: types.maybeNull(types.number),
    current_ap_nft: types.maybeNull(types.number),
    current_ap_owner: types.maybeNull(types.number),
    current_ap_token: types.maybeNull(types.number),
    current_life_gauge: types.maybeNull(types.number),
    max_life_gauge: types.maybeNull(types.number),
    image_url: types.maybeNull(types.string),
    contract_address: types.maybeNull(types.string),
    chain_id: types.maybeNull(types.string),
    max_recovery: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    nft_characteristic: types.maybeNull(types.boolean),
    nft_information_id: types.maybeNull(types.string),
    nft_price: types.maybeNull(types.number),
    owner_characteristic: types.maybeNull(types.boolean),
    rarity: types.maybeNull(types.string),
    recovery_remain: types.maybeNull(types.number),
    sale_status: types.maybeNull(types.string),
    token_characteristic: types.maybeNull(types.boolean),
    token_type: types.maybeNull(types.string),
    token_id: types.maybeNull(types.string),
    // order_histories: types.maybeNull(types.string),
    order_id: types.maybeNull(types.string),
    owner_avatar: types.maybeNull(types.string),
    owner_name: types.maybeNull(types.string),
    prize_hunter_init_value: types.maybeNull(types.number),
    prize_hunter_trait: types.maybeNull(types.boolean),
    treasure_hunter_init_value: types.maybeNull(types.number),
    treasure_hunter_trait: types.maybeNull(types.boolean),
    nft_hunter_model: types.maybeNull(NftHunterModal),
    nft_bounty_ball_model: types.maybeNull(NftBountyModal),
    nft_gauntlet_model: types.maybeNull(NftGauntletModal),
    rarity_model: types.maybeNull(RarityModel),
    description: types.maybeNull(types.string),
    fee_rate: types.maybeNull(types.string),
  })
  .views(self => ({
    get isCheckoutValid() {
      return self?.status === 'SALE' && self.displayStatus === 'PUBLIC' && !self?.holding;
    },

    get isBidValid() {
      return (
        self.productBid?.status === 'NEW' &&
        self.bidFlag &&
        self?.status === 'SALE' &&
        self.displayStatus === 'PUBLIC' &&
        self.typeSale === 'LIMITED'
      );
    },

    get isBidTimeValid() {
      const currentTime = moment(self.currentTime);
      const startTime = moment(self.productBid.startTime);
      const endTime = moment(self.productBid.endTime);

      const isTimeValid = currentTime.diff(startTime, 's') >= 0 && endTime.diff(currentTime, 's') > 0;

      return isTimeValid;
    },

    get isCancelBidTimeValid() {
      const currentTime = moment(self.currentTime);
      const startTime = moment(self.productBid.startTime);
      const endTime = moment(self.productBid.endTime);

      const isTimeValid = currentTime.diff(startTime, 's') >= 0 && endTime.diff(currentTime, 's') > 600;

      return isTimeValid;
    },

    get isSellValid() {
      return self.displayStatus === 'PUBLIC';
    },

    get isParent() {
      return self.tokenType === 'MULTI';
    },

    get isMulti() {
      return self.parentProductId || self.tokenType === 'MULTI';
    },
  }))
  .actions(self => ({
    setHolding(value) {
      self.holding = value;
    },

    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const Product = types
  .model('Product')
  .props({
    product_id: types.identifier,
    ap_arena_hunter: types.maybeNull(types.number),
    ap_mechanic: types.maybeNull(types.number),
    ap_merchant: types.maybeNull(types.number),
    ap_prize_hunter: types.maybeNull(types.number),
    ap_treasure_hunter: types.maybeNull(types.number),
    arena_hunter_init_value: types.maybeNull(types.number),
    mechanic_init_value: types.maybeNull(types.number),
    merchant_init_value: types.maybeNull(types.number),
    mechanic_trait: types.maybeNull(types.boolean),
    merchant_trait: types.maybeNull(types.boolean),
    arena_hunter_trait: types.maybeNull(types.boolean),
    nft_hunter_id: types.maybeNull(types.string),
    arena_characteristic: types.maybeNull(types.boolean),
    currency_unit: types.maybeNull(types.string),
    current_ap_arena: types.maybeNull(types.number),
    current_ap_nft: types.maybeNull(types.number),
    current_ap_owner: types.maybeNull(types.number),
    current_ap_token: types.maybeNull(types.number),
    current_life_gauge: types.maybeNull(types.number),
    image_url: types.maybeNull(types.string),
    max_recovery: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    nft_characteristic: types.maybeNull(types.boolean),
    nft_information_id: types.maybeNull(types.string),
    nft_price: types.maybeNull(types.number),
    owner_characteristic: types.maybeNull(types.boolean),
    rarity: types.maybeNull(types.string),
    recovery_remain: types.maybeNull(types.number),
    sale_status: types.maybeNull(types.string),
    token_characteristic: types.maybeNull(types.boolean),
    token_type: types.maybeNull(types.string),
    token_id: types.maybeNull(types.string),
    // order_histories: types.maybeNull(types.string),
    owner_avatar: types.maybeNull(types.string),
    owner_name: types.maybeNull(types.string),
    prize_hunter_init_value: types.maybeNull(types.number),
    prize_hunter_trait: types.maybeNull(types.boolean),
    treasure_hunter_init_value: types.maybeNull(types.number),
    treasure_hunter_trait: types.maybeNull(types.boolean),
    nft_hunter_model: types.maybeNull(NftHunterModal),
    nft_bounty_ball_model: types.maybeNull(NftBountyModal),
    nft_gauntlet_model: types.maybeNull(NftGauntletModal),
    rarity_model: types.maybeNull(RarityModel),
    chain_id: types.maybeNull(types.string),
    contract_address: types.maybeNull(types.string),
    order_id: types.maybeNull(types.string),
    user_id: types.maybeNull(types.string),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const Products = types.model('Products').props({
  items: types.array(Product),
  page: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
});

const ProductsStore = Model.named('ProductsStore')
  .props({
    categories: types.array(Category),
    topProducts: Products,
    searchProducts: Products,
    hightlightEventProduct: types.maybeNull(Product),
    eventProducts: Products,
    myProducts: Products,
    mySellProducts: types.array(Product),
    mySoldProducts: types.array(Product),
    myBidProducts: Products,
    currentProductDetails: types.maybeNull(ProductDetails),
    hotRankingProducts: Products,
    recommendedProducts: Products,
    myProductsWish: Products,
    bidsHistory: types.array(BidsHistory),
    firstTwoBidHistories: types.array(BidsHistory),
    randomNumber: types.maybeNull(types.number),
    gachaPickupProducts: types.array(Product),
    myOfferProducts: types.array(Product),
    productItems: Products,
    newProducts: Products,
    auctionProduct: Products,
    erc1155Product: Products,
    creatorProducts: Products,
    minimumOfferPrice: types.maybeNull(types.number),
    stakingStatus: types.maybeNull(types.string),
  })
  .actions(self => ({
    setBidsHistory(payload) {
      return (self.bidsHistory = payload);
    },

    setFirstTwoBidHistories(payload) {
      return (self.firstTwoBidHistories = payload);
    },

    regenerateRandomNumber() {
      self.randomNumber = Math.floor(Math.random() * 999);
    },

    getMyProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_MY_PRODUCTS,
        api: getMyProducts,
        payload: {
          ...payload,
          findType: 'OWNED',
        },
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.myProducts = {
            items: concat ? self.myProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getProductDetails(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_DETAILS,
        api: getProductDetails,
        payload,
        onSuccess: result => {
          if (!result) return null;
          self.currentProductDetails = result;
          return null;
        },
      });
    },

    getProductCategories(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_CATEGORIES,
        api: getProductCategories,
        payload,
        onSuccess: result => {
          self.categories = result;
        },
      });
    },

    getProductFilterData(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_FILTER_DATA,
        api: getProductFilterData,
        payload,
      });
    },

    getPriceRate(payload) {
      return self.request({
        type: TYPES.GET_PRICE_RATE,
        api: getPriceRate,
        payload,
      });
    },

    getTransactionHistories(payload) {
      return self.request({
        type: TYPES.GET_TRANSACTION_HISTORIES,
        api: getTransactionHistories,
        payload,
      });
    },

    favorite(payload) {
      return self.request({
        type: TYPES.FAVORITE,
        api: favorite,
        payload,
      });
    },

    parseProducts(items) {
      return items.map(item => ({
        ...item,
        isNew: moment(item.currentTime).diff(moment(item.createdAt), 'h') <= NEW_RANGE_HOURS,
      }));
    },

    getMyTransactionHistories(payload) {
      return self.request({
        type: TYPES.GET_MY_TRANSACTION_HISTORIES,
        api: getMyTransactionHistories,
        payload,
      });
    },

    getProductPrice(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_PRICE,
        api: getProductPrice,
        payload,
      });
    },

    getAuthorProductPrice(payload) {
      return self.request({
        type: TYPES.GET_AUTHOR_PRODUCT_PRICE,
        api: getAuthorProductPrice,
        payload,
      });
    },

    getProductItems(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCT_ITEMS,
        api: getProductItems,
        payload,
        onSuccess: result => {
          const items = result.records;

          self.productItems = {
            items: concat ? self.productItems.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getMyPageProductItems(payload, { concat }) {
      return self.request({
        type: TYPES.GET_MY_PAGE_PRODUCT,
        api: getProductMyPageItems,
        payload,
        onSuccess: result => {
          const items = result.records;

          self.productItems = {
            items: concat ? self.productItems.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getAuctionProduct(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_ITEMS,
        api: getProductItems,
        payload,
        onSuccess: result => {
          self.auctionProduct = {
            items: result.result,
            page: result.page,
            total: result.total,
          };
        },
      });
    },
  }));

export { TYPES };
export default ProductsStore.create({
  topProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  searchProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  eventProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  myProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  mySellProducts: [],
  mySoldProducts: [],
  myBidProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  currentProductDetails: null,
  hotRankingProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  recommendedProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  myProductsWish: {
    items: [],
    page: 1,
    total: 0,
  },
  myOfferProducts: [],
  productItems: {
    items: [],
    page: 1,
    total: 0,
    pages: 1,
  },
  newProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  auctionProduct: {
    items: [],
    page: 1,
    total: 0,
  },
  erc1155Product: {
    items: [],
    page: 1,
    total: 0,
  },
  creatorProducts: {
    items: [],
    page: 1,
    total: 0,
  },
});
