import { types } from 'mobx-state-tree';

const UiStore = types
  .model('UiStore')
  .props({
    sideBarStatus: types.boolean,
    theme: types.string,
    isCulturePorn: types.boolean,
    cultureSearch: types.string,
    bgHeaderDetail: types.maybeNull(types.string),
  })
  .actions(self => ({
    toggleSideBar() {
      self.sideBarStatus = !self.sideBarStatus;
    },
    setTheme(value) {
      self.theme = value;
    },
    setThemeCulturePorn(value) {
      self.isCulturePorn = value;
    },
    setcultureSearch(value) {
      self.cultureSearch = value;
    },
    setBgHeaderDetail(payload) {
      self.bgHeaderDetail = payload;
    },
  }));

export default UiStore.create({
  sideBarStatus: true,
  theme: 'dark',
  isCulturePorn: false,
  cultureSearch: '',
});
