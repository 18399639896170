export const STORAGE = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  USER_ID: 'USER_ID',
  CURRENT_LANG: 'CURRENT_LANG',
  PUBLIC_ADDRESS: 'PUBLIC_ADDRESS',
  GAME_URL: 'GAME_URL',
  AUTH_CODE: 'AUTH_CODE',
  LOGIN_TYPE: 'LOGIN_TYPE',
  CHAIN_ID: 'CHAIN_ID',
  EMAIL_REMEMBER: 'EMAIL_REMEMBER',
  EMAIL: 'EMAIL',
  TYPE_BOUNTY: 'TYPE_BOUNTY',
  ORDER_CODE_SLASH: 'ORDER_CODE_SLASH',
  USER_DATA: 'USER_DATA',
};

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
  return localStorage.setItem(key, value);
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(key);
}

export function clearLocalStorage() {
  return localStorage.clear();
}
