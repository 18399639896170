import styled from 'styled-components';

export const ButtonThirdPartyWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  gap: 8px;
  height: 50px;
  border: 2px solid black;
  background-color: white;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -3px 0px inset, 0px 3px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  &:hover {
    opacity: 0.7;
  }

  .button_title {
    font-family: 'DF-SouGei-W5', sans-serif;
    font-weight: 500;
    font-size: 19px;
  }

  .button_logo {
    position: absolute;
    max-width: 20%;
    max-height: 20px;
    left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ButtonColor = {
  green: {
    mainColor: '#3BF055',
    borderColor: '#58F88D',
    topBorderColor: '#BBFFDD',
    bottomBorderColor: '#26AA62',
  },
  brown: {
    mainColor: '#5C5279',
    borderColor: '#73698D',
    topBorderColor: '#A29CB6',
    bottomBorderColor: '#423862',
  },
  blue: {
    mainColor: '#1E8FFE',
    borderColor: '#0D38A8',
    topBorderColor: '#2E94F9',
    bottomBorderColor: '#1C7BF7',
  },
  pink: {
    mainColor: '#1C7BF7',
    borderColor: '#EA3897',
    topBorderColor: '#FB77D8',
    bottomBorderColor: '#B00E56',
  },
  yellow: {
    mainColor: '#FECF3E',
    borderColor: '#FECF3E',
    topBorderColor: '#FEDE68',
    bottomBorderColor: '#FECF3E',
  },
};

export const ButtonCustomWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  height: 50px;
  border: 2px solid black;
  background-color: ${props => `${ButtonColor[props.color]?.mainColor}`};
  border-radius: 12px;
  cursor: pointer;
  box-shadow: ${props => `${ButtonColor[props.color]?.bottomBorderColor}`} 0px -3px 0px inset,
    inset 0px 2px 0px 0px ${props => `${ButtonColor[props.color]?.topBorderColor}`},
    inset 0px -2px 2px 2px ${props => `${ButtonColor[props.color]?.borderColor}`};
  position: relative;
  &:hover {
    opacity: 0.7;
  }

  &::before {
    content: '';
    display: block;
    height: 45%;
    position: absolute;
    top: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
    background: #fff;
    border-radius: 5px 5px 2px 2px;
    opacity: 0.3;
  }

  &::after {
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    top: 0.3rem;
    left: 5px;
    transform: translateX(-50%);
    width: 4px;
    background: #fff;
    border-radius: 100%;
    transform: skewY(-20deg);
    opacity: 0.7;
  }

  .button_title {
    font-family: 'DF-SouGei-W5', sans-serif;
    color: white;
    font-weight: 500;
    font-size: 19px;
    text-shadow: 1px 1px 0 #000000, -1px 1px 0 #000000, 1px -1px 0 #000000, -1px -1px 0 #000000;
    z-index: 2;
  }

  .button_logo {
    position: absolute;
    max-width: 20%;
    left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonPlayGameWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  .button_image {
    position: absolute;
  }
  &:disabled {
    cursor: not-allowed;
  }
  .button_title {
    position: relative;
    top: -2px;
    z-index: 10;
    font-family: 'DF-SouGei-W5', sans-serif;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
    text-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 1);
    @supports (-webkit-text-stroke: 3px #000000) {
      color: white;
      -webkit-text-stroke-width: 3px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }
`;
