import { MainApi, GameApi } from './endpoint';

export function getUserInfo() {
  return MainApi.get(`/users/me`);
}

export function getUserResource() {
  return GameApi.get(`/users/me-resource`);
}

export function getUsersWallet({ ...payload }) {
  return MainApi.get(`/users`, payload);
}

export function updateUserInfo({ id, ...payload }) {
  return MainApi.put(`/user/identity/${id}`, payload);
}
