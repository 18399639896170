export const ROUTE_OPTION = [
  {
    path: 'product-details',
    name: 'Item Details',
    parent: 'Home',
    pathParent: '/',
  },
  {
    path: 'my-page',
    name: 'My Page',
    parent: 'Home',
    pathParent: '/',
  },
  {
    path: 'my-store',
    name: 'My Store',
    parent: 'Home',
    pathParent: '/',
  },
  {
    path: 'landing',
    name: 'Presale',
    pathParent: '/',
  },
  {
    path: 'presale',
    name: 'presale',
    pathParent: '/',
  },
];
