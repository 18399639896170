import { Checkbox, Form, Spin } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { LOGIN_TYPE } from '../../constants/common';
import { usePassportProvider } from '../../contexts/PassportProvider';
import { useWeb3Auth } from '../../contexts/web3auth/web3auth';
import { Images } from '../../theme';
import { COLOR_GOOGLE_ICON, EMAIL_BLACK, EMAIL_GRAY, PASSPORT_ICON } from '../../theme/images';
import { getLocalStorage, removeLocalStorage, setLocalStorage, STORAGE } from '../../utils/storage';
import { ButtonCustom, ButtonThirdParty } from '../button-component';
import InputType from '../inputType';
import Modal from '../modal';
import { HeavyTextBlack } from '../text';

const ContentStyled = styled.div`
  padding: 35px 24px 24px;
  margin-top: 35px;
  position: relative;
  background-image: url(${`${Images.BG_PRESALE_LAYER_1}`});
  background-color: transparent;
  background-repeat: round;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const ImageAvatar = styled.img`
  position: absolute;
  width: 82px;
  height: 82px;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'DF-SouGei-W5', sans-serif;

  padding: 12px;
  background-image: url(${`${Images.BG_PRESALE_LAYER_2}`});
  background-repeat: round;
  h1 {
    color: #011e5a;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0;
    margin-top: 5px;
  }
  .item-email {
    margin-top: 25px;
    margin-bottom: 0px !important;
  }
  .input-email {
    margin-bottom: 0px;
    border: 2px solid #0099fe;
    box-shadow: none;
    .ant-input {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
      &::placeholder {
        color: #cecfd3;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .form-btn {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    > button,
    > div {
      margin-bottom: 16px;
    }
  }
  .form-remember {
    display: flex;
    align-items: center;
    margin: 5px 0 0 1px;
    gap: 6px;

    .form-remember-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
  }
  .text-or {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: -8px 0px -8px;
    @supports (-webkit-text-stroke: 1.5px #000000) {
      color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
      -webkit-paint-order: stroke fill; // Safari
    }
  }
`;

const CheckBoxStyled = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #0e69f2;
    background-color: #0e7ff2;
    box-shadow: none;
  }
  .ant-checkbox-checked {
    border: none;
    border-radius: 6px;
    box-shadow: none;
    .ant-checkbox-inner {
      border: none;
      border-radius: 6px;
      box-shadow: none;
      &::after {
        border: none;
        box-shadow: none;
        width: 17px;
        height: 14px;
        background-image: url(${`${Images.CHECKED_ICON}`});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

const LoginModal = ({ isOpen, onClose = () => {}, setModalToast }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const { login: loginPassportAsync } = usePassportProvider();
  const formik = useFormik({
    initialValues: {
      email: getLocalStorage(STORAGE.EMAIL_REMEMBER),
      remember: getLocalStorage(STORAGE.EMAIL_REMEMBER) ? true : false,
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        // eslint-disable-next-line
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, t('validation_messages:EMAIL_INVALID'))
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(40, t('validation_messages:MAX_LENGTH_30'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email, remember }) => {
      handleSubmitLogin(email);
      if (remember) {
        setLocalStorage(STORAGE.EMAIL_REMEMBER, email);
      } else {
        removeLocalStorage(STORAGE.EMAIL_REMEMBER);
      }
    },
  });

  const { loginAuth0EmailPasswordLess, loginGoogle } = useWeb3Auth();

  const { touched, handleSubmit, values, errors, setFieldValue, setFieldTouched } = formik;

  const handleCloseModal = () => {
    // formik.resetForm({});
    if (onClose) onClose();
  };

  const handleSubmitLogin = async value => {
    try {
      setLoading(true);
      await loginAuth0EmailPasswordLess(value, LOGIN_TYPE.WEB3_AUTH);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      handleCloseModal();
      setLoading(false);
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      await loginGoogle(LOGIN_TYPE.WEB3_AUTH);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      handleCloseModal();
      setLoading(false);
    }
  };

  const handleLoginWithPassport = async () => {
    try {
      setLoading(true);
      const res = await loginPassportAsync();
      if (res instanceof Error) {
        throw res;
      }
    } catch (error) {
      if (error.type === 'AUTHENTICATION_ERROR') {
        onClose();
        return;
      }
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="login-modal-custom"
        padding={0}
        title={null}
        width={357}
        color="blue"
        closable={false}
      >
        <Spin spinning={loading}>
          <ContentStyled>
            <ImageAvatar src={Images.AVATAR_LOGIN} alt="avatar" />
            <FormContent>
              <h1>{t('login.account_connect').toUpperCase()}</h1>
              <>
                <Form.Item
                  className="item-email"
                  validateStatus={errors?.email && touched?.email ? 'error' : ''}
                  help={
                    <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                  }
                >
                  <InputType
                    className="input-email"
                    prefix={
                      <img src={values?.email ? EMAIL_BLACK : EMAIL_GRAY} className="site-form-item-icon" alt="icon" />
                    }
                    placeholder={t('login.please_enter_email')}
                    name="email"
                    onChange={text => {
                      setFieldValue && setFieldValue('email', text.target.value || '');
                    }}
                    value={values?.email}
                    onBlur={() => setFieldTouched('email', true)}
                    disabled={loading}
                  />
                </Form.Item>
                <div className="form-remember">
                  <CheckBoxStyled
                    onChange={text => {
                      setFieldValue && setFieldValue('remember', text.target.checked);
                    }}
                    value={values?.remember}
                    isChecked={values?.remember}
                    checked={values?.remember}
                    colorScheme="teal"
                  />
                  <HeavyTextBlack className="form-remember-text">{t('login.remember_me')}</HeavyTextBlack>
                </div>
              </>
              <div className="form-btn">
                <ButtonCustom color="blue" disabled={loading} onClick={handleSubmit}>
                  {t('login.login_with_email').toUpperCase()}
                </ButtonCustom>
                <ButtonThirdParty logo={COLOR_GOOGLE_ICON} disabled={loading} onClick={handleLoginGoogle}>
                  {t('login.sign_in_goole').toUpperCase()}
                </ButtonThirdParty>

                <ButtonThirdParty logo={PASSPORT_ICON} disabled={loading} onClick={handleLoginWithPassport}>
                  IMMUTABLE
                </ButtonThirdParty>
              </div>
            </FormContent>
          </ContentStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginModal;
