/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { EnvironmentNames } from '../constants/environment';
import { config, passport } from '@imtbl/sdk';
import {
  LOGOUT_REDIRECT_URI,
  REDIRECT_URI,
  IMMUTABLE_CLIENT_ID,
  IMMUTABLE_PUBLISHABLE_KEY,
} from '../constants/passport';
import useLocalStorage from '../hooks/useLocalStorage';

const env = process.env.REACT_APP_ENV;
const currentEnvironment = {
  product: EnvironmentNames.PRODUCTION,
  staging: EnvironmentNames.STAGING,
  develop: EnvironmentNames.DEV,
};

const getPassportConfig = environment => {
  const sharedConfigurationValues = {
    redirectUri: REDIRECT_URI,
    logoutRedirectUri: LOGOUT_REDIRECT_URI,
    audience: 'platform_api',
    scope: 'openid offline_access email transact',
  };

  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return {
        baseConfig: {
          environment: config.Environment.PRODUCTION,
          publishableKey: IMMUTABLE_PUBLISHABLE_KEY,
        },
        clientId: IMMUTABLE_CLIENT_ID,
        ...sharedConfigurationValues,
      };
    }
    case EnvironmentNames.STAGING: {
      return {
        baseConfig: {
          environment: config.Environment.SANDBOX,
          publishableKey: IMMUTABLE_PUBLISHABLE_KEY,
        },
        clientId: IMMUTABLE_CLIENT_ID,
        ...sharedConfigurationValues,
      };
    }
    case EnvironmentNames.DEV: {
      const baseConfig = {
        environment: config.Environment.SANDBOX,
        publishableKey: IMMUTABLE_PUBLISHABLE_KEY,
      };
      return {
        baseConfig,
        clientId: IMMUTABLE_CLIENT_ID,
        ...sharedConfigurationValues,
      };
    }
    default: {
      throw new Error('Invalid environment');
    }
  }
};

const ImmutableContext = createContext({
  passportClient: new passport.Passport(getPassportConfig(currentEnvironment[env || develop])),
  environment: currentEnvironment[env || develop],
});

export function ImmutableProvider({ children }) {
  const [environment, setEnvironment] = useLocalStorage(
    'IMX_PASSPORT_SAMPLE_ENVIRONMENT',
    useContext(ImmutableContext).environment,
  );
  const [passportClient, setPassportClient] = useState(useContext(ImmutableContext).passportClient);

  useEffect(async () => {
    const passportInstance = new passport.Passport(getPassportConfig(environment));
    passportInstance.connectEvm();
    setPassportClient(passportInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerValues = useMemo(
    () => ({
      passportClient,
      setEnvironment,
      environment,
      setPassportClient,
    }),
    [passportClient, setEnvironment, environment, setPassportClient],
  );

  return <ImmutableContext.Provider value={providerValues}>{children}</ImmutableContext.Provider>;
}

export function useImmutableProvider() {
  const context = useContext(ImmutableContext);
  if (!context) {
    throw new Error('useImmutableProvider must be used within an ImmutableProvider');
  }
  return context;
}
