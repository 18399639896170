import { types } from 'mobx-state-tree';
import { getUserInfo, getUserResource, updateUserInfo } from '../api/users';
import { Model } from '../utils/mobx-model-helper';
import { getLocalStorage, setLocalStorage, STORAGE } from '../utils/storage';

const TYPES = {
  GET_USER_INFO: 1,
  UPDATE_USER_INFO: 2,
  GET_USER_RESOURCE: 2,
};

const UserData = types.model('UserData').props({
  userName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  userImage: types.maybeNull(types.string),
  balance: types.maybeNull(types.number),
});

const UsersStore = Model.named('UsersStore')
  .props({
    userData: UserData,
  })
  .actions(self => ({
    getUserInfo(payload) {
      return self.request({
        type: TYPES.GET_USER_INFO,
        api: getUserInfo,
        payload,
        onSuccess: result => {
          if (!result) return;
          const userData = {
            ...(getLocalStorage(STORAGE.USER_DATA) ? JSON.parse(getLocalStorage(STORAGE.USER_DATA)) : self?.userData),
            userName: result?.username || result?.email,
            userId: result?.id,
            userImage: result?.avatar_url,
          };
          self.userData = userData;
          setLocalStorage(STORAGE.USER_DATA, JSON.stringify(userData));
        },
      });
    },

    getUserResource(payload) {
      return self.request({
        type: TYPES.GET_USER_RESOURCE,
        api: getUserResource,
        payload,
        onSuccess: result => {
          if (!result) return;
          const userData = {
            ...(getLocalStorage(STORAGE.USER_DATA) ? JSON.parse(getLocalStorage(STORAGE.USER_DATA)) : self?.userData),
            balance: result?.current_bcoin || 0,
          };
          self.userData = userData;
          setLocalStorage(STORAGE.USER_DATA, JSON.stringify(userData));
        },
      });
    },

    updateUserInfo(payload) {
      return self.request({
        type: TYPES.UPDATE_USER_INFO,
        api: updateUserInfo,
        payload,
      });
    },
  }));

export { TYPES };
export default UsersStore.create({
  userData: {
    userName: null,
    userId: null,
    userImage: null,
    balance: null,
  },
});
