export const MAX_INPUT_VALUE = 1e20;
export const MAX_LENGTH_INPUT_VALUE_INTEGER = 12;
export const MAX_LENGTH_INPUT_VALUE_FRACTION = 4;
export const ALLOWED_MAX_SIZE_FILE = 5 * 1024 * 1024;

export const DAPP_WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link',
    // icon: metamaskWallet,
  },
  {
    name: 'Trust',
    link: 'https://link.trustwallet.com/open_url?coin_id=60&url=',
    // icon: trustWallet,
  },
];

export const LOGIN_TYPE = {
  TCG: 'TCG',
  WEB3_AUTH: 'WEB3_AUTH',
  IMMUTABLE: 'IMMUTABLE',
};

export const WALLETS_TYPE = {
  BICONOMY: 'BICONOMY',
  TCG: 'TCG',
  DM2: 'DM2',
  IMMUTABLE: 'IMMUTABLE',
};

export const BUY_TYPE = {
  NATIVE_TOKEN: 'NATIVE_TOKEN',
  NORMAL: 'NORMAL',
  RESALE_NORMAL: 'RESALE_NORMAL',
  CREDIT: 'CREDIT', // Using stripe payment
  CRYPTO: 'CRYPTO', // Using slash payment
  WALLET: 'WALLET',
};

export const TYPE_POINT = {
  RM_POINT: 'RM_POINT',
  FREE_POINT: 'FREE_POINT',
};

export const TYPE_TAB = {
  SELL: 'SELL',
  MY_ITEMS: 'MY_ITEMS',
};

export const CARD_STATUS = {
  NEW: 'SELL',
  SALE: 'SALE',
  BUYING: 'BUYING',
  SOLD: 'SOLD',
  RE_SALE: 'RE_SALE',
  READY_FOR_SALE: 'READY_FOR_SALE',
};

export const CHAIN_ID_AVAILABLE = {
  POLYGON_MAINNET: 137,
  POLYGON_TESTNET: 80002,

  IMX_MAINNET: 13371,
  IMX_TESTNET: 13473,

  OASYS_MAINNET: 248,
  OASYS_TESTNET: 9372,

  TCG_MAINNET: 2400,
  TCG_TESTNET: 12401,

  BNB_MAINNET: 56,
  BNB_TESTNET: 97,
};

export const PROVIDERS = {
  // 1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  // 3: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  // 4: 'https://speedy-nodes-nyc.moralis.io/2cac97baa7c919b144ee567f/eth/rinkeby',
  // 5: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  // 42: `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  // [CHAIN_ID_AVAILABLE.BNB_MAINNET]: 'https://bsc-dataseed1.binance.org',
  // [CHAIN_ID_AVAILABLE.BNB_TESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  // 421611: 'https://rinkeby.arbitrum.io/rpc',
  // 81: 'https://rpc.shibuya.astar.network:8545',
  // [CHAIN_ID_AVAILABLE.POLYGON_MAINNET]: 'https://polygon-rpc.com',
  // 80001: 'https://speedy-nodes-nyc.moralis.io/2cac97baa7c919b144ee567f/polygon/mumbai',
  // 43113: 'https://api.avax-test.network/ext/bc/C/rpc',
  // 43114: 'https://api.avax.network/ext/bc/C/rpc',
  // [CHAIN_ID_AVAILABLE.OASYS_TESTNET]: 'https://rpc.testnet.oasys.games',
  // [CHAIN_ID_AVAILABLE.OASYS_MAINNET]: 'https://rpc.mainnet.oasys.games',
  // [CHAIN_ID_AVAILABLE.POLYGON_TESTNET]: 'https://rpc-amoy.polygon.technology',
  [CHAIN_ID_AVAILABLE.TCG_MAINNET]: process.env.REACT_APP_RPC_TCG_MAINNET,
  [CHAIN_ID_AVAILABLE.TCG_TESTNET]: process.env.REACT_APP_RPC_TCG_TESTNET,
  [CHAIN_ID_AVAILABLE.IMX_MAINNET]: process.env.REACT_APP_RPC_IMX_MAINNET,
  [CHAIN_ID_AVAILABLE.IMX_TESTNET]: process.env.REACT_APP_RPC_IMX_TESTNET,
};

export const BICONOMY_API_KEY = {
  [CHAIN_ID_AVAILABLE.POLYGON_TESTNET]: 'o3WflLyuX.5c8c266e-526f-4c80-84de-5a2c38c53e35',
  [CHAIN_ID_AVAILABLE.POLYGON_MAINNET]: 'bgE7eByCe.cee064c6-a2de-4eba-9134-27656369ab35',
  [CHAIN_ID_AVAILABLE.BNB_TESTNET]: 'p1NsNUdaM.2de75473-25ba-4b73-97f2-80112c0bd815',
  [CHAIN_ID_AVAILABLE.BNB_MAINNET]: '_TC0gZ41k.8e20d4eb-cbda-4fa8-89ef-28470e4cce62',
  11155111: 'BVhDOotTs.450058ee-7d79-4f4a-9cb5-c575ce5de57e',
  1: 'XHKgGbfQI.03f0e9a3-7ec9-4f63-879d-5c58e2d71375',
};

export const EVENT_WEBSOCKET = {
  BUY_GENESIS_NFT: 'BUY_GENESIS_NFT',
};

export const getWallets = (wallets, chainId) => {
  if (!wallets || !chainId) return '';

  let type = '';
  switch (Number(chainId)) {
    case CHAIN_ID_AVAILABLE.POLYGON_MAINNET:
    case CHAIN_ID_AVAILABLE.POLYGON_TESTNET:
      type = WALLETS_TYPE.BICONOMY;
      break;
    case CHAIN_ID_AVAILABLE.TCG_MAINNET:
    case CHAIN_ID_AVAILABLE.TCG_TESTNET:
      type = WALLETS_TYPE.DM2; // TODO: will change to TCG later
      break;
    case CHAIN_ID_AVAILABLE.IMX_MAINNET:
    case CHAIN_ID_AVAILABLE.IMX_TESTNET:
      type = WALLETS_TYPE.IMMUTABLE;
      break;
    default:
      type = '';
  }
  const walletAddress = wallets.find(wallet => wallet?.wallet_type === type);
  return walletAddress?.public_address || null;
};

export const CHAIN_NAME = {
  [CHAIN_ID_AVAILABLE.IMX_MAINNET]: 'Immutable',
  [CHAIN_ID_AVAILABLE.IMX_TESTNET]: 'Immutable',

  [CHAIN_ID_AVAILABLE.TCG_MAINNET]: 'TCG',
  [CHAIN_ID_AVAILABLE.TCG_TESTNET]: 'TCG',
};

export const CHAIN_EXPLORER = {
  [CHAIN_ID_AVAILABLE.IMX_MAINNET]: process.env.REACT_APP_EXPLORER_IMX_MAINNET,
  [CHAIN_ID_AVAILABLE.IMX_TESTNET]: process.env.REACT_APP_EXPLORER_IMX_TESTNET,

  [CHAIN_ID_AVAILABLE.TCG_MAINNET]: process.env.REACT_APP_EXPLORER_TCG_MAINNET,
  [CHAIN_ID_AVAILABLE.TCG_TESTNET]: process.env.REACT_APP_EXPLORER_TCG_TESTNET,
};



