import { Dropdown } from 'antd';
import styled from 'styled-components';

export const DropdownWrapper = styled(Dropdown)`
  .arrow {
    transition: transform 330ms ease-in-out;
    transform: ${props => (props.open ? `rotate(0)` : `rotate(180deg)`)};
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
`;
